<template>
  <div>
    <el-row type="flex" align="middle">
      <el-col :span="12">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
          <el-breadcrumb-item>申请提现</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="12" style="text-align: right">
        <el-link type="primary" @click="$router.push('/cashing-records')">提现记录</el-link>
      </el-col>
    </el-row>
    <el-divider class="divider"></el-divider>
    <bill-table cash-out-list not-show-pagination></bill-table>
  </div>
</template>

<script>
import BillTable from "@/views/income/components/BillTable"

/**
 * 待提现列表
 */
export default {
  name: "Cash-outList",
  components:{
    BillTable
  },
  mounted() {
  },
}
</script>

<style scoped>
.divider{
  margin-top: 15px;
  background-color: #f5f5f5;
}
</style>
